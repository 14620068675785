exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-credits-js": () => import("./../../../src/pages/credits.js" /* webpackChunkName: "component---src-pages-credits-js" */),
  "component---src-pages-ideas-js": () => import("./../../../src/pages/ideas.js" /* webpackChunkName: "component---src-pages-ideas-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-templates-ru-blog-list-template-js": () => import("./../../../src/templates/ru/blogListTemplate.js" /* webpackChunkName: "component---src-templates-ru-blog-list-template-js" */),
  "component---src-templates-ru-blog-template-js": () => import("./../../../src/templates/ru/blogTemplate.js" /* webpackChunkName: "component---src-templates-ru-blog-template-js" */),
  "component---src-templates-ru-devlog-list-template-js": () => import("./../../../src/templates/ru/devlogListTemplate.js" /* webpackChunkName: "component---src-templates-ru-devlog-list-template-js" */),
  "component---src-templates-ru-make-list-template-js": () => import("./../../../src/templates/ru/makeListTemplate.js" /* webpackChunkName: "component---src-templates-ru-make-list-template-js" */),
  "component---src-templates-ru-paranormal-list-template-js": () => import("./../../../src/templates/ru/paranormalListTemplate.js" /* webpackChunkName: "component---src-templates-ru-paranormal-list-template-js" */),
  "component---src-templates-ru-science-list-template-js": () => import("./../../../src/templates/ru/scienceListTemplate.js" /* webpackChunkName: "component---src-templates-ru-science-list-template-js" */)
}

